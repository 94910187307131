@import '../../../../../../../src/style/abstract/variables';
@import '../../../../../../../src/style/abstract/media';
@import '../../../../../../../src/style/abstract/button';
@import '../../../../../../../src/style/abstract/loader';
@import '../../../../../../../src/style/abstract/icons';
@import '../../../../../../../src/style/abstract/parts';
@import '../../../../../../../src/style/abstract/mixins';

.BlogPostDetails {
    .ProductLinks-Wrapper {
        padding-left: 0;
        padding-right: 0;
    }

    .BlogRelatedProducts {
        &-Wrapper {
            .ProductLinks-List {
                @include wide-desktop {
                    grid-template-columns: repeat(3, 1fr);

                    .ProductCard {
                        max-width: 100%;
                    }
                }

                @include narrow-desktop {
                    grid-template-columns: repeat(3, 1fr);
                }

                @include tablet {
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            .ProductLinks-Title {
                font-size: 2.2rem;
                line-height: 3.2rem;
                font-weight: 400;
                text-align: left;
                padding-bottom: 24px;
            }
        }
    }
}