@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewForm {
    padding-top: 10px;

    &-ProductName {
        font-weight: 700;
        font-size: 24px;
    }

    &-Content {
        input,
        textarea {
            font-size: 12px;
        }
    }

    &-Rating {
        [dir="rtl"] & {
            display: flex;
            flex-direction: row-reverse;
        }

        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-block-start: 12px;
        margin-block-end: 26px;
        margin-inline: 0;
        text-align: start;
        direction: rtl;
        border: 0;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`

        &:first-child {
            @include mobile {
                margin-block-start: 0;
            }
        }

        input {
            cursor: pointer;
        }
    }

    &-Legend {
        font-size: 14px;
        order: 1;
        padding-right: 16px;
    }

    & &-Button {
        margin-block-start: 12px;
        width: 100%;
    }

    input,
    textarea {
        width: 100%;
    }

    textarea {
        height: 116px;
    }
}

@include desktopAndAbove {
    .ProductReviewForm {
        & &-Button {
            max-width: 270px;
        }
    }
}
