@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductConfigurableAttributeDropdown {
    margin-block-start: 0;

    &_isUnselected {
        animation: var(--shake-animation);
    }
}
