@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductConfigurableAttributes {
    font-size: 14px;
    line-height: 20px;

    &-SwatchList,
    &-DropDownList {
        text-align: center;
        margin-block-start: 2px;
        margin-block-end: 12px;
    }

    &-Title {
        font-size: 12px;
        margin-block: 12px;
    }

    &-SwatchList {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding-inline-start: 3px;
        padding-block-start: 3px;
    }

    &-DropDownList {
        width: 100%;
    }

    &-Placeholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        height: var(--option-size);
        width: var(--option-size);
        margin-block: var(--option-margin-block);
        margin-inside: var(--option-margin-inline);
    }

    &-Expandable:first-of-type {
        border-block-start: none;
    }

    &-Expandable.ExpandableContent:last-of-type {
        border-block-end: none;
    }
}
