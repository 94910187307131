@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductWishlistButton {
    @include default-transition(transform, .2s);

    background: $color-neutral-30;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        display: block;

        path {
            fill: $color-neutral-90;
        }
    }

    &_isInWishlist {
        background: $color-primary-40;

        svg {
            path {
                fill: $color-neutral-5;
            }
        }
    }

    &:hover {
        transform: scale(1.1);
    }
}
