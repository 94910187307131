@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductTabs {
    border-block-end: 2px solid $color-neutral-30;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    &-Wrapper {
        max-width: 1180px;
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }
}

#StickyContainer {
    .ProductTabs {
        &-Wrapper {
            padding: 0;
            margin-left: 0;
            margin-right: 380px;
        }
    }
}

@include mobileAndTablet {
    .ProductTabs {
        position: fixed;
        top: 65px;
        left: 0;
        width: 100%;
        background: $color-neutral-5;
        z-index: 2;
        opacity: 0;
        visibility: hidden;

        @include default-transition(opacity);

        &_inViewPort {
            opacity: 1;
            visibility: visible;
        }
    }
}

@include tablet {
    .ProductTabs {
        top: 125px;
    }
}

@include tabletAndAbove {
    .ProductTabs {
        justify-content: flex-start;
    }
}

@include desktopAndAbove {
    .ProductTabs {
        &-Wrapper {
            padding: 0 20px;
        }
    }
}
