@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAdditionalInfo {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;

    &_alternative {
        display: block;

        .ProductAdditionalInfo {
            &-Item {
                border-bottom: 1px solid $color-neutral-30;
                width: 100%;
                margin-bottom: 0;
                padding: 16px 0;
            }

            &-Text {
                display: flex;

                a {
                    display: flex;
                }

                div {
                    &:first-child {
                        color: $color-neutral-90;
                    }
                }
            }

            &-Angle {
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);

                svg {
                    display: block;
                }
            }
        }
    }

    &-Item {
        width: 50%;
        display: flex;
        margin-bottom: 26px;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    &-Icon {
        margin-right: 15px;
        display: flex;
    }

    &-Angle {
        display: none;
    }

    &-Text {
        div {
            line-height: 18px;

            &:first-child {
                color: $color-neutral-50;
                padding-right: 5px;
            }

            &:last-child {
                color: $color-neutral-90;
                text-decoration: underline;
            }
        }

        &_hightlight {
            div:last-child {
                color: $color-secondary-60;
            }
        }
    }

    &-Drawer {
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.5px;

        a {
            font-weight: 400;
            color: $color-primary-50;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

@include mobileAndTablet {
    .ProductAdditionalInfo {
        border-bottom: 1px solid $color-neutral-30;
        padding-bottom: 20px;
    }
}

@include tablet {
    .ProductAdditionalInfo {
        flex-wrap: nowrap;
    }
}

@include desktopAndAbove {
    .ProductAdditionalInfo {
        padding-bottom: 30px;
    }
}
