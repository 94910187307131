@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewList {
    &-Title {
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        margin-block-start: 36px;
    }

    button {
        width: 100%;
        margin-top: 30px;
        display: block;
    }
}

@include tablet {
    .ProductReviewList {
        button {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@include tabletAndAbove {
    .ProductReviewList {
        button {
            width: 250px;
        }
    }
}


@include desktopAndAbove {
    .ProductReviewList {
        &-List {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
        }

        button {
            margin-top: 45px;
        }
    }
}
