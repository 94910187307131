@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviewItem {
    margin: 50px 0 0;
    word-break: break-word;
    padding: 0;

    &::before {
        content: none;
    }

    &-ReviewTitle {
        width: 100%;
        margin-block-end: 12px;
        font-weight: 700;
        grid-area: title;

        @include mobile {
            margin-block-end: 14px;
        }
    }

    &-RatingSummary {
        grid-area: ratings;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    &-RatingSummaryItem {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-block-end: 16px;
        margin-right: 30px;

        @include mobile {
            margin-block-end: 7px;
        }

        &:last-of-type {
            margin-block-end: 0;
        }

        .ProductReviewRating {
            --star-size: 20px;
        }
    }

    &-Content {
        grid-area: content;

        h4 {
            font-weight: 400;
            line-height: 20px;
        }

        p {
            color: $color-neutral-70;
            line-height: 20px;
        }
    }

    &-ReviewAuthor {
        margin-block: 0;
        font-size: 12px;
    }

    &-ReviewDetails {
        margin-block-end: 0;
        text-align: justify;

        @include mobile {
            margin-block-end: 14px;
        }

        .TextPlaceholder {
            margin-block-start: 6px;
            line-height: 1.1;

            @include mobile {
                margin-block-start: 7px;
            }

            &::after {
                display: block;
            }
        }
    }

    .ProductReviewRating-Counter {
        display: none;
    }

    &-RatingItem {
        margin-block: auto;
    }
}

@include mobile {
    .ProductReviewItem {
        &-RatingSummary {
            justify-content: space-between;
            text-align: right;
        }
    }
}

@include desktopAndAbove {
    .ProductReviewItem {
        padding: 0 20px 0 0;

        &-RatingSummary {
            margin-bottom: 24px;
        }
    }
}
