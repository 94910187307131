@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductInstallment {
    margin-bottom: 48px;
    padding-bottom: 40px;
    padding-top: 40px;
    border-bottom: 1px solid $color-neutral-30;

    .TypographyHeader {
        margin-top: 0;
    }

    &-Wrapper {
        padding: 0;
    }

    &-Logo {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }

    &-Button {
        width: 183px;
        margin-left: auto;
        margin-right: auto;

        @include default-transition();

        &:hover {
            opacity: 0.6;
        }
    }

    &-Text {
        padding: 24px 0 34px;
        text-align: center;
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;
        color: $color-neutral-70;
    }
}

@include mobile {
    .ProductInstallment {
        &-Text {
            max-width: 310px;
        }
    }
}

@include tablet {
    .ProductInstallment {
        &-Text {
            max-width: 580px;
        }
    }
}
