@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductInformation {
    font-size: 14px;
    padding-bottom: 40px;
    padding-top: 40px;
    border-bottom: 1px solid $color-neutral-30;

    .TypographyHeader {
        margin-top: 0;
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Description {
        @include default-list;

        ul, ol {
            li {
                &::before {
                    inset-inline-start: 0;
                    top: 8px;
                    border-radius: 50%;
                }
            }
        }

        * {
            color: $color-neutral-50;
            letter-spacing: 0.5px;
            line-height: 20px;
        }

        a {
            text-decoration: underline;

            &:hover {
                color: $color-neutral-90;
            }
        }

        h1, h2, h3, h4, h5, h6 {
            font-size: 20px;
            font-weight: 400;

            span {
                font-weight: 400;
                color: $color-neutral-90;
            }
        }
    }
}

@include desktopAndAbove {
    .ProductInformation {
        padding-bottom: 48px;
        padding-top: 48px;
    }
}
