@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductComparePage {
    padding-bottom: 60px;

    .ContentWrapper {
        @include page-container;

        min-height: 300px;
    }
}
