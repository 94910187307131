@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductGalleryThumbnailImage {
    @include desktop {
        display: flex;
        height: auto;
        width: 100%;
        background-color: var(--color-white);
    }

    img {
        background-color: $white;
    }
}
