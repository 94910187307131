@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductAttributeValue {
    .Field {
        &-CheckboxLabel {
            width: 100%;
        }
    }

    &-Label {
        display: flex;
        justify-content: space-between;
        padding-right: 17px;
        width: 100%;
        font-size: 13px;
    }

    &-LabelText {
        display: block;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    &-Count {
        padding-left: 10px;
    }

    &-ColorSwatch, &-ImageSwatch {
        display: block;

        .input-control {
            opacity: 0;
        }
    }

    &-ColorSwatchColor, &-ImageSwatchImage {
        width: 16px;
        height: 16px;
        background: var(--option-background-color);
        border: 1px solid var(----option-border-color);
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 2px;
        --option-size: 12px;

        overflow-y: hidden;

        &::after {
            content: '';
            position: absolute;
            opacity: var(--option-is-selected);
            transition: opacity 200ms;
            will-change: opacity, border-inline-start, border-inline-end;
            height: calc(var(--option-size) / 5);
            inset-inline-start: calc(var(--option-size) / 2.4);
            inset-block-start: calc(var(--option-size) / 2.4);
            width: calc(var(--option-size) / 2.5);
            transform: rotate(-45deg);

            [dir="rtl"] & {
                transform: scale(-1, 1) rotate(45deg);
            }

            border-inline-start: 2px solid var(--option-check-mark-background);
            border-block-end: 2px solid var(--option-check-mark-background);
        }
    }

    &-ImageSwatchImage {
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

@include mobileAndTablet {
    .ProductAttributeValue {
        &-Label {
            padding-right: 0;
        }
    }
}
