@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductTab {
    &-Item {
        margin: 0;
        padding-inline-start: 0;

        &:last-child {
            margin: 0;
            padding: 0;
        }

        &::before {
            content: '';
            width: 100%;
            height: 3px;
            bottom: -2px;
            top: auto;
            left: 50%;
            transform: translateX(-50%);
            background: $color-primary-50;
            opacity: 0;

            @include default-transition(opacity);
        }

        &_isActive {
            &::before {
                opacity: 1;
            }

            .ProductTab-Button {
                color: $color-neutral-90;
            }
        }
    }

    &-Button {
        font-size: 14px;
        line-height: 20px;
        margin: 0;
        padding: 8px 10px;
        cursor: pointer;
        letter-spacing: 0.5px;
        color: $color-neutral-70;
    }

    &-Header {
        font-size: 20px;
    }
}

@include tabletAndAbove {
    .ProductTab {
        &-Button {
            padding: 16px 34px;
        }
    }
}
