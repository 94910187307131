@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-compare-title-width: 350px;
}

.ProductCompareAttributeRow {
    padding: 30px 0 29px 0;

    &-Title,
    &-Value {
        font-size: 16px;
        line-height: 28px;
    }

    &-Title {
        font-weight: 500;
        padding-right: 24px;
        width: var(--product-compare-title-width);
        max-width: 300px;
    }

    &-Value {
        color: $color-neutral-70;
        width: var(--product-compare-column-width);
        max-width: var(--product-compare-column-width);
    }

    &-Values {
        > * {
            width: var(--product-compare-column-width);
            max-width: var(--product-compare-column-width);
        }
    }

    &-OutOfStock {
        padding-inline-start: 0;
        padding-block-start: 0;
    }
}

[dir="ltr"] .ProductCompareAttributeRow-Value {
    padding-left: 0;
    padding-right: 24px;
}

@include mobileAndTablet {
    .ProductCompareAttributeRow {
        flex-wrap: wrap;

        &-Title {
            width: 100%;
            flex: 0 0 100%;
            padding-bottom: 16px;
            padding-left: 0;
        }
    }
}
