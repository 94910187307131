@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductReviews {
    padding-bottom: 40px;
    padding-top: 40px;
    border-bottom: 1px solid $color-neutral-30;
    border-top: 1px solid $color-neutral-30;

    .TypographyHeader {
        margin-top: 0;
    }

    @include desktop {
        z-index: 1;
    }

    &-Summary {
        align-self: stretch;
        display: flex;
        flex-wrap: wrap;
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    & &-Button {
        width: 170px;
    }

    &-SummaryDetails {
        display: inline-block;
        margin-inline-start: 12px;
        margin-block: auto;

        @include mobile {
            margin-inline-start: 14px;
        }

        span {
            &::before {
                content: ' / ';
            }
        }
    }

    &-Popup {
        .Popup-Content {
            max-width: 510px;
        }
    }
}

@include mobile {
    .ProductReviews {
        & &-Button {
            margin-top: 20px;
        }
    }
}

@include tabletAndAbove {
    .ProductReviews {
        & &-Button {
            margin-left: 35px;
        }

        &-SummaryWrapper {
            align-self: stretch;
            display: flex;
        }
    }
}

@include desktopAndAbove {
    .ProductReviews {
        padding-bottom: 48px;
        padding-top: 48px;
    }
}
