@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCardBanner {
    list-style: none;
    max-height: 390px;
    padding: 0;

    &::before {
        display: none;
    }
}
