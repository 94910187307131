@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.RelatedProductsByAttribute {
    &-Group {
        padding-bottom: 10px;
    }

    &-RelatedProducts {
        display: flex;
        flex-wrap: wrap;

        &_variant_secondary {
            .RelatedProductsByAttribute {
                &-RelatedProduct {
                    width: 60px;
                    height: 52px;

                    &_isText {
                        width: auto;
                        height: 40px;
                    }
                }
            }
        }
    }

    &-RelatedProduct {
        height: 56px;
        background: $color-neutral-5;
        cursor: pointer;
        margin: 0 16px 10px 0;
        border: 1px solid $color-neutral-40;
        color: $color-primary-60;
        overflow: hidden;

        @include default-transition(border, .2s);

        img {
            height: 100%;
            width: auto;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
        }

        &:hover, &_isCurrent {
            border-color: $color-primary-60;
        }

        &_isDisabled {
            opacity: 0.4;
        }

        &_isText {
            width: auto;
            padding: 0 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            height: 40px;
        }
    }

    &-Label {
        margin-bottom: 8px;

        &_variant_secondary {
            display: none;
        }
    }
}

.ProductCard {
    &_layout_list {
        .RelatedProductsByAttribute {
            &-RelatedProducts {
                &_variant_secondary {
                    display: flex;
                }
            }
        }
    }
}

@include mobile {
    .RelatedProductsByAttribute {
        &-RelatedProducts {
            &_variant_primary {
                overflow-x: auto;
                white-space: nowrap;
                display: block;
                margin-right: -20px;

                .RelatedProductsByAttribute-RelatedProduct {
                    display: inline-block;
                    line-height: 38px;
                }
            }
        }
    }
}

@include mobileAndTablet {
    .RelatedProductsByAttribute {
        &-RelatedProducts {
            &_variant_secondary {
                .RelatedProductsByAttribute {
                    &-RelatedProduct {
                        margin: 0 9px 10px 0;
                    }
                }
            }
        }

        &-RelatedProduct {
            width: 74px;
        }
    }
}

@include desktopAndAbove {
    .RelatedProductsByAttribute {
        &-RelatedProducts {
            &_variant_primary {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                grid-gap: 15px;
            }

            &_variant_secondary {
                grid-gap: 10px;
            }
        }

        &-RelatedProduct {
            margin: 0;
        }
    }
}
