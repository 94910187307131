@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-rating-background: #979797;
    --product-rating-filled-background: var(--primary-base-color);
    --product-rating-size: 18px;

    @include mobile {
        --product-rating-size: 21px;
    }
}

.ProductReviewRating {
    display: flex;
    align-items: center;
    line-height: normal;

    svg {
        width: 14px;
        height: 14px;
        fill: $color-other-3;
    }

    &_isLoading {
        &::before {
            background-image: var(--placeholder-image);
            background-size: var(--placeholder-size);
            animation: var(--placeholder-animation);
        }
    }

    &-Counter {
        padding-left: 18px;
        display: inline-block;
        font-size: 12px;
        letter-spacing: 0;
    }

    &_size {
        &_normal {
            svg {
                width: 20px;
                height: 20px;
                margin-right: 1px;
            }
        }

        &_large {
            svg {
                width: 24px;
                height: 24px;
                margin-right: 7px;
            }
        }
    }
}
