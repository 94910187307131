@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-card-picture-mask-color: #f5f5f5;
    --product-card-color-border-color: #efefef;
    --product-card-text-border-color: #979797;
    --product-card-picture-label-background: rgba(255, 255, 255, 0.7);
    --product-card-reviews-background: rgba(243, 243, 243, 0.55);
    --product-card-wishlist-button-background: rgba(243, 243, 243, 0.78);
    --product-card-wishlist-button-color: #d8d5d5;
    --product-card-compare-button-background: var(
        --product-card-wishlist-button-background
    );
    --product-card-compare-button-color: var(
        --product-card-wishlist-button-color
    );
    --product-card-name-max-rows: 2;
    --product-card-brand-line-height: 1.2em;
    --product-card-color-size: 32px;
    --product-card-background: var(--color-white);
    --option-margin-block: 0 12px;
    --option-margin-inline: 0 12px;
}

.ProductCard {
    padding: 0;

    &:hover {
        @include desktop {
            box-shadow: 0 15px 20px rgba(0, 0, 0, 0.08);
            z-index: 4;

            .ProductCard-VisibleOnHover {
                background: var(--product-card-background);
                opacity: 1;
                box-shadow: 0 15px 20px rgba(0, 0, 0, 0.08);
                z-index: -1;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                flex-grow: 1;
                position: absolute;
                inset-block-start: 100%;
                width: 100%;
            }

            .ProductCard-FigureAlternative {
                opacity: 1;
            }
        }
    }

    &::before {
        content: none;
    }

    &-Labels {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-LabelsWithActions {
        position: absolute;
        z-index: 2;
        right: 0;
    }

    &-ProductActions {
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        margin-top: 8px;

        .ProductWishlistButton {
            margin-bottom: 15px;
        }
    }

    &-DiscountLabel {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    &-DeliveryTime {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px;

        span {
            color: $color-primary-60;
        }
    }

    &-Content {
        background: var(--product-card-background);
        padding-inline: 24px;
        display: flex;
        flex-direction: column;
    }

    &-Name {
        padding-block-end: 2px;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 25px;
        font-weight: normal;
        margin-top: 0;
    }

    &-Picture {
        padding-block-end: 100%;
    }

    &-PictureMissing {
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        inset-block-end: 0;
        margin: auto;
        inset-block-start: 0;
    }

    &-Image {
        display: inline-block;
        width: var(--product-card-color-size);
        height: var(--product-card-color-size);
        border-radius: 50%;
        overflow: hidden;
        margin-block-start: 5px;
        margin-inline-end: 7px;
        border: 1px solid var(--product-card-color-border-color);
    }

    &-PriceWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    &-Price {
        width: 100%;
    }

    .ProductPrice {
        &-PriceValue {
            &_isSpecialPrice {
                color: $color-secondary-50;
            }
        }

        .DiscountLabel {
            display: none;
        }
    }

    &-PriceBadge {
        color: var(--secondary-dark-color);
        font-size: 10px;
        margin-block-end: 0;
    }

    &-Figure {
        flex-grow: 1;
    }

    &-FigureAlternative,
    &-FigureRelatedProduct {
        background: $color-neutral-5;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        opacity: 0;

        @include default-transition(opacity, 0.2s);
    }

    &-FigureRelatedProduct {
        opacity: 1;
    }

    &-Link {
        color: inherit;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:hover,
        &:focus {
            text-decoration: none;
            color: initial;
        }
    }

    .TextPlaceholder,
    .Image_isPlaceholder {
        opacity: 0;
        animation: appear 100ms 300ms forwards;
    }

    &-VisibleOnHover {
        flex-direction: column;
        justify-content: flex-end;
        flex-grow: 1;
        padding-block-start: 0;
        padding-block-end: 0;
        padding-inline: 24px;
        opacity: 0;
        position: absolute;
        inset-block-start: 100%;
        display: none;
    }

    &_siblingsHaveTierPrice,
    &_siblingsHavePriceBadge {
        .ProductCard-PriceWrapper {
            min-height: 40px;
        }
    }

    &-FigureReview {
        margin-bottom: 16px;
    }

    &-Reviews {
        margin-bottom: 12px;
    }

    &_layout_list {
        margin-bottom: 30px;

        .ProductCard {
            &-Content {
                justify-content: space-between;
                margin-inline-start: 30px;
                flex-direction: row;
                align-items: center;
                flex: 1;
                padding: 0;
            }

            &-Link {
                flex-direction: row;
                width: 100%;
            }

            &-ActionWrapper {
                align-items: center;
                display: flex;
            }

            &-ProductActions {
                flex-direction: row-reverse;
                display: flex;
                position: relative;
                top: 0;
                right: 0;

                > div {
                    margin-block-end: 0;
                    margin-right: 20px;
                }
            }

            &-MainInfo {
                flex: 1;
            }

            &-PriceWrapper {
                flex-grow: unset;
            }

            &-Name {
                font-size: 16px;
                margin-bottom: 16px;
            }

            &-FigureReview {
                margin-bottom: 0;
            }

            &-Picture {
                display: block;
                width: 320px;
            }

            &-Labels {
                position: absolute;
                left: 0;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                align-items: start;
            }

            &-DiscountLabel {
                position: relative;
            }

            &-VisibleOnHover {
                padding-left: 350px;
            }
        }
    }
}

@include mobile {
    .ProductCard {
        &_isSlider {
            .ProductCard-Content {
                padding: 6px 0 0;
            }

            .ProductCard-Name {
                margin-bottom: 5px;
                height: 40px;
                overflow: hidden;
            }

            .ProductCard-Price {
                order: 1;
            }

            .ProductCard-Labels {
                display: none;
            }
        }

        &-ProductActions {
            margin-top: 30px;
            margin-right: 0;

            .ProductWishlistButton {
                margin-bottom: 8px;
            }

            .ProductWishlistButton,
            .ProductCompareButton {
                transform: scale(0.7);

                &:hover {
                    transform: scale(0.8);
                }
            }
        }

        &-Content {
            padding-left: 14px;
            padding-right: 14px;
        }

        &-Price {
            flex-wrap: wrap;
        }

        &-DiscountLabel {
            font-size: 11px;
            padding: 5px 12px;
            display: inline-block;
            position: static;
        }

        &-Labels {
            align-items: flex-start;

            .Label {
                padding: 5px 5px 4px;
                font-size: 10px;
                letter-spacing: 0;
            }
        }

        &-LabelsMobile {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            max-width: 75%;
        }
    }
}

@include mobileAndTablet {
    .ProductCard {
        &_isSlider {
            .ProductCard-ProductActions {
                display: none;
            }

            .ProductCard-VisibleOnHover {
                display: none;
            }
        }
    }
}
