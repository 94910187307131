@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductPrice {
    display: flex;

    >span {
        margin-right: 12px;
    }

    &-HighPrice {
        order: 1;
        color: $color-neutral-50;

        &::after {
            position: absolute;
            content: '';
            left: 0;
            top: 9px;
            width: 100%;
            height: 1px;
            background: $color-neutral-50;
        }
    }

    .DiscountLabel {
        display: inline-block;
        margin-right: 12px;
    }

    &-PriceBadge {
        padding-right: 5px;
    }
}

@include mobile {
    .ProductPrice {
        font-size: 12px;
    }
}
