@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.CheckoutSuccessProduct {
    display: grid;
    grid-gap: 20px;
    align-items: flex-start;
    padding: 2rem 0rem;
    border-bottom: 1px solid $color-neutral-30;


    img {
        max-width: 100px;
    }


    &-Box {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    &-Box:first-of-type {
        flex-direction: row;
    }

    &-Box:last-of-type {
        text-align: right;
    }

    &-SubBox {
        padding-left: 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-OriginalPrice {
        &_isCrossed {
            text-decoration: line-through;
            color: $color-neutral-50;
            font-size: 1.15rem;
        }
    }

    &-SpecialPrice {
        margin-bottom: 0;
    }

    &-DeliveryBox {
        display: flex;
        align-items: center;
        font-size: 14px;

        > span {
            margin-left: 8px;
            letter-spacing: 0.5px;
        }

        svg {
            top: -1px;
        }
    }
}

@include mobile {
    .CheckoutSuccessProduct {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);

        &-MobileProductTitle {
            text-align: center;
            color: $color-neutral-70;
            font-size: 1.2rem;
            margin-bottom: 1rem;
        }

        &-Box:last-of-type {
            >p {
                text-align: right;
            }
        }


        &-Box:first-of-type {
            grid-area: 1 / 1 / 2 / 4;
            margin-bottom: 1rem;
        }
    }
}

@include tabletAndAbove {
    .CheckoutSuccessProduct {
        grid-template-columns: 1fr 88px 88px 88px;
        padding: 4rem 2rem;

        &-MobileProductTitle {
            display: none;
        }

        img {
            max-width: 120px;
        }

        &-SubBox {
            padding-left: 16px;
        }
    }
}

@include desktopAndAbove {
    .CheckoutSuccessProduct {
        grid-template-columns: 1fr 115px 115px 115px;

        &-SubBox {
            padding-left: 32px;
        }

        img {
            max-width: 200px;
        }
    }
}
