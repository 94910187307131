@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --product-compare-column-gap: 24px;
    --product-compare-sidebar-width: 330px;
    --product-compare-item-gap: 24px;
    --product-compare-column-width-mobile: 300px;
    --product-compare-column-width: calc(270px + var(--product-compare-item-gap) * 2);
}

.ProductCompare {
    @include scrollbar;

    &-Heading {
        font-weight: 500;
        margin-top: 24px;

        &_noProducts {
            text-align: center;
        }
    }


    .ClearButton {
        align-items: flex-end;
        justify-content: center;
        padding: 0 var(--product-compare-column-gap) 32px 0;
        max-width: 300px;

        .Button {
            max-width: 242px;
            margin-inline-end: 0;
        }
    }

    .ProductPrice {
        font-size: 20px;
        font-weight: 500;
        min-height: 0;
        padding: 0 var(--product-compare-column-gap) 0 0;
        display: flex;
        align-items: center;

        > * {
            display: inline-block;
            line-height: 28px;
        }

        &_hasDiscount {
            color: $color-secondary-50;
            font-size: 24px;

            .ProductPrice-Price {
                order: 1;
                display: inline-block;
            }

            .ProductPrice-HighPrice {
                order: 2;
                line-height: 20px;
                opacity: 1;
                display: inline-block;
                color: $color-neutral-50;
                margin: 0 0 0 16px;
                font-size: 15px;
                font-weight: 400;
            }
        }

        &-SubPrice {
            display: none;
        }

        &-PriveValue {
            margin: 0;
        }
    }

    &-Empty {
        margin-left: 0;
        text-align: center;
        margin-top: 6.5rem;
    }

    &-InfoText {
        margin-top: 2rem;
        font-size: 2rem;
    }

    &-BackButton {
        margin-top: 6rem;
        margin-bottom: 2rem;

        &:hover {
            color: var(--button-color);
        }
    }
}

[dir="ltr"] .ProductCompare {
    &-Item {
        padding-right: var(--product-compare-column-gap);
    }
}

@include mobileAndTablet {
    .ProductCompare {
        &-Item,
        .ProductPrice,
        .ProductCompareAttributeRow-Value {
            width: var(--product-compare-column-width-mobile);
        }

        &-FirstColumn.ClearButton {
            width: 100%;
            padding: 32px 0;

            .Button {
                width: 100%;
            }
        }
    }
}
