@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

.ProductCompareItem {
    padding: 0 0 32px;

    &-Details {
        margin-bottom: 32px;
    }

    &-Figure {
        width: 110px;
        height: 82px;
        margin-right: 16px;
    }

    &-Title {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        padding-inline-end: 45px;
    }

    .Button {
        max-height: 48px;
        width: 100%;
        flex: 1 1 auto;
    }

    .ProductWishlistButton {
        margin-right: 0;
        flex: 0 0 28px;
        background: none;
        width: auto;
        height: auto;
        margin-left: 16px;

        svg {
            width: 22px;
            height: 22px;
        }

        path {
            fill: $color-neutral-50;
        }

        &_isInWishlist {
            path {
                fill: $color-neutral-90;
            }
        }
    }
}
